import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ErrorToast, HorizontalScroller } from 'components';
import DonutGraphContainer from './components/DonutGraphContainer/DonutGraphContainer';

import {formatDonutData} from './helpers';

import InsightsService from 'api/insights';

import './donutGraphs.scss';

const DonutGraphs = ({
  getDepartmentsFilter,
  setSelectedTile,
  selectedTile,
  fromDate,
  toDate,
  viewingAccount,
  compare,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    fetchHealthOverview();
  }, [fromDate, toDate, viewingAccount]);

  const fetchHealthOverview = async () => {
    try {
      const departmentFilter = getDepartmentsFilter();
      if (departmentFilter === 'NA') return;
      setIsLoading(true);
      const {
        data: { data },
      } = await InsightsService.getInsightStatus({
        departments: departmentFilter,
        date: toDate,
      });
      const fData = formatDonutData(data);
      setGraphData(fData);
      if (compare) setGraphData(fData.slice(0, 4));
      setIsLoading(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="insi-w-dgraphs">
      <HorizontalScroller>
        {graphData.map((data) => (
          <DonutGraphContainer
            key={data.header}
            isLoading={isLoading}
            selectedTile={selectedTile}
            setSelectedTile={setSelectedTile}
            activeCount={data.count}
            totalCount={data.totalCount}
            status={data.status}
            color={data.color}
            score={data.score}
            icon={data.icon}
            header={data.header}
            toDate={toDate}
          />
        ))}
      </HorizontalScroller>
    </div>
  );
};
  
  export default DonutGraphs;
  